@import "~antd/dist/reset.css";
@import "./App.less";

.bg_main_div {
  background: radial-gradient(#2e1811 20%, #0f0f11 70%);
  /* background: radial-gradient(cyan 0%, transparent 20%, salmon 40%); */
  /* min-height: 100vh !important; */
  /*  padding: 0% 10% !important; */
  background-repeat: no-repeat;
  background-size: 100% 900px;
}
.bg_main_div2 {
  /* background: radial-gradient(#2e1811 20%, #0f0f11 70%); */
  background-repeat: no-repeat;
  background-size: 100% 900px;
}
.bg_container {
  width: 100%;
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
}
/* @media screen and (max-width: 1280px) {
  .bg_container {
    padding: 0px 5%;
  }
} */
.header_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.5% 0%;
  background-color: transparent;
  width: 100%;
}
@media screen and (max-width: 1280px) {
  .header_div {
    padding: 3% 5%;
  }
}

.middleDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/* .invest_btn {
  background: transparent;
  border: none;
  font-family: "Hellix";
  font-style: normal;
  font-size: 16px;
  color: white;
  font-weight: bold;
} */
.about {
  font-family: "Hellix";
  margin-left: 30px;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  color: white;
}
.about:hover,
.portfolio:hover,
.blog:hover,
.contact:hover {
  color: white;
}
.portfolio {
  margin-left: 30px;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 600;
  color: white;
  font-size: 16px;
}
.blog {
  margin-left: 30px;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 600;
  color: white;
  font-size: 16px;
}
.contact {
  margin-left: 30px;
  font-family: "Hellix";
  font-weight: 600;
  font-style: normal;
  color: white;
  font-size: 16px;
}
.mobile_header_div {
  padding: 2% 0%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .mobile_header_div {
    padding: 2% 5%;
  }
}
@media screen and (max-width: 576px) {
  .mobile_header_div {
    padding: 5% 5%;
  }
}
.drawerDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  left: 40%;
  top: 40%;
}
.drawerAbout {
  margin-top: 30px;
  color: white;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
}
.drawerPortfolio {
  margin-top: 30px;
  color: white;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
}
.drawerBlog {
  margin-top: 30px;
  color: white;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
}
.drawerContact {
  margin-top: 30px;
  color: white;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
}
.drawerAbout:hover,
.drawerPortfolio:hover,
.drawerBlog:hover,
.drawerContact:hover {
  color: white !important;
}

.copyright_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  position: absolute;
  /* margin: 0 auto; */
  margin-left: 23%;
  margin-bottom: 25px;
}
.copyright_text {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.8;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.footer_icon_div {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 768px) {
  .copyright_text {
    font-size: 14px;
  }
}
