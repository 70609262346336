.homeDrawer {
  .ant-drawer-wrapper-body {
    .ant-drawer-body {
      padding: unset !important;
      background-color: red;
    }
  }
}
.invest_btn {
  background: transparent;
  border: none;
  font-family: "Hellix-bold";
  font-style: normal;
  font-size: 16px;
  color: white;
  font-weight: 900;
  background-color: #f04a14;
  padding: 14px 24px 14px 24px;
  height: auto;
}
.invest_btn:hover {
  color: white !important;
}
@media screen and (max-width: 992px) {
  .getinto_back {
    height: 309px !important;
  }
}
