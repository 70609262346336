.portfolioTitle {
  font-family: "Hellix-bold";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  color: #f04a14;
  /* margin-top: 160px; */
}
/* @media screen and (max-width: 1280px) {
  .portfolioTitle {
    padding: 0px 5%;
  }
} */
.latest_div_items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 60px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  padding: 0% 0px 2% 0px;
  align-items: center;
}
@media screen and (max-width: 576px) {
  .latest_div_items {
  }
}
.latest_div_title {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
}
@media screen and (max-width: 1200px) {
  .latest_div_title {
    font-size: 18px;
  }
}
.insights_user_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 25%;
}
.header_insights_user_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 15%;
}
@media screen and (max-width: 1200px) {
  .insights_user_div {
    width: 18%;
  }
}
@media screen and (max-width: 768px) {
  .insights_user_div {
    width: 23%;
  }
  .header_insights_user_div {
    width: 40% !important;
  }
  .header_insight_date_info {
    width: 40% !important;
  }
}
@media screen and (max-width: 576px) {
  .header_insights_user_div {
    width: 50% !important;
  }
  .header_insight_date_info {
    width: 50% !important;
  }
}
.username_text {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  margin-left: 6%;
}
@media screen and (max-width: 991px) {
  .username_text {
    font-size: 12px;
  }
}
.insight_user_calender_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
}
.header_insight_user_calender_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3%;
  padding-bottom: 2%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
@media screen and (max-width: 576px) {
  .blogHeader {
    font-size: 26px !important;
  }
  .header_insight_user_calender_div {
    margin-top: 10%;
    padding-bottom: 5%;
  }
}
@media screen and (max-width: 992px) {
  .blogHeader {
    padding-top: 80px !important;
    font-size: 36px !important;
  }
}
.insight_date_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 20%;
}
.header_insight_date_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 15%;
}
@media screen and (max-width: 1200px) {
  .insight_date_info {
    width: 22%;
  }
}
@media screen and (max-width: 992px) {
  .insight_date_info {
    width: 25%;
  }
}
@media screen and (max-width: 768px) {
  .insight_date_info {
    width: 35%;
  }
}

.insight_date_info_text {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  margin-left: 6%;
}
@media screen and (max-width: 991px) {
  .insight_date_info_text {
    font-size: 12px;
  }
}
.headerBlogSection {
  padding: 0px 10%;
}
@media screen and (max-width: 767px) {
  .gradientSection {
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.2); */
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    background: radial-gradient(#f04a14 -160%, #0f1011 70%);
    padding: unset !important;
  }
}

.blogHeader {
  font-family: "Rifton";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  text-align: center;
  color: #f04a14;
  padding-top: 200px;
}
.head_blog_heading {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: #ffffff;
  margin-top: 2%;
}
.head_blog_text {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #ffffff;
  margin-top: 25px;
}
.head_blog_text2 {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #ffffff;
  margin-top: 25px;
}
.blogSection {
  padding-top: 5% !important;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 5% !important;
}
@media screen and (max-width: 1280px) {
  .blogSection {
    padding: 0 5%;
  }
}
.blogDetail_div {
  width: 70%;
}
@media screen and (max-width: 768px) {
  .latest_div_items {
    flex-direction: column;
  }
  .blogDetail_div {
    width: 100%;
  }
  .aeroImage_div {
    margin-top: 15px;
    width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .insight_date_info {
    width: 50%;
  }
  .insights_user_div {
    width: 40%;
  }
}
