@import "./Header.less";

.header_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1% 5%;
  background-color: transparent;
  width: 100%;
}

.middleDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.invest_btn {
  /* width: 172px; */
  /* height: 48px; */
  background: transparent;
  border: none;
  font-family: "Hellix";
  font-style: normal;
  font-size: 16px;
  color: white;
}
.about {
  font-family: "Hellix";
  margin-left: 30px;
  font-style: normal;
  font-size: 16px;
  color: white;
}
.portfolio {
  margin-left: 30px;
  font-family: "Hellix";
  font-style: normal;
  color: white;
  font-size: 16px;
}
.blog {
  margin-left: 30px;
  font-family: "Hellix";
  font-style: normal;
  color: white;
  font-size: 16px;
}
.contact {
  margin-left: 30px;
  font-family: "Hellix";
  font-style: normal;
  color: white;
  font-size: 16px;
}
.copyright_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* width: 30%; */
  justify-content: space-between;
}
.copyright_text {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.8;
  /* width: 100%; */
}
.footer_icon_div {
  margin-left: 15%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  /* width: 50%; */
}
.footer_div {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid white;
  padding: 3% 0%;
  /* width: 80%; */
  max-width: 1280px;
  margin: 60px auto 0 auto;
  align-items: center;
}
@media screen and (max-width: 1280px) {
  .footer_div {
    padding: 3% 5%;
  }
}
@media screen and (max-width: 767px) {
  .footer_div {
    flex-direction: column;
  }
  .footer_icon_div {
    margin-left: unset;
    margin-top: 95px;
  }
  .footer_image_div {
    margin-top: 50px;
  }
  .copyright_text {
    margin-top: 45px;
    margin-bottom: 20px;
  }
}
.discordIcon {
  margin-left: 2%;
  width: 60px;
}
.twitterIcon {
  /* margin-right: 5%; */
  width: 60px;
}
@media screen and (max-width: 768px) {
  .copyright_text {
    /* font-size: 10px; */
  }
  .twitterIcon {
    width: unset;
  }
  .discordIcon {
    width: unset;
  }
}
.getinTouch_div {
  margin-top: 130px;
  position: relative;
}
.ready_card_text_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ready_card_title {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  /* identical to box height, or 36px */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.ready_card_text {
  font-family: "Hellix";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  margin-top: 10px;
  /* identical to box height, or 24px */

  text-align: center;

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

@media screen and (max-width: 576px) {
  .ready_card_text {
    font-size: 12px;
    margin-top: 5px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .footer_icon_div {
    margin-left: unset;
    margin-top: 25px;
  }
  .getinTouch_div {
    margin-top: 30px;
    position: relative;
  }
}


