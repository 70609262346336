.getin_btn {
  width: 160px;
  height: 48px;
  margin-top: 25px;
  background-color: orangered !important;
  border: none;
  font-family: "Hellix-bold";
  font-style: normal;
  font-size: 16px;
  color: white;
  font-weight: 900;
}
@media screen and (max-width: 991px) {
  .getin_btn {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .getin_btn {
    margin-top: 10px;
    width: 120px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
}
.getin_btn:hover {
  color: white !important;
}

.twitterIcon,
.discordIcon {
  width: 40px !important;
}

@media screen and (max-width: 768px) {
  .twitterIcon,
  .discordIcon {
    width: 40px !important;
  }
}
