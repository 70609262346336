.bg_main_div {
  background: radial-gradient(#2e1811 20%, #0f0f11 70%);
  /* background: radial-gradient(cyan 0%, transparent 20%, salmon 40%); */
  /* min-height: 100vh !important; */
  /*  padding: 0% 10% !important; */
  background-repeat: no-repeat;
  background-size: 100% 900px;
}
.bg_main_div2 {
  /* background: radial-gradient(#2e1811 20%, #0f0f11 70%); */
  background-repeat: no-repeat;
  background-size: 100% 900px;
}
.bg_container {
  width: 100%;
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
}
