.landing_collapsebar {
  margin-top: 75px !important;
  border: none;
}

@media screen and (max-width: 1280px) {
  .landing_collapsebar {
    padding: 0px 5%;
  }
}

.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: unset;
}
> .ant-collapse-header .ant-collapse-expand-icon {
}
.ant-collapse-header-text {
  color: white !important;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  padding: 2.5% 0%;
}
@media screen and (max-width: 1200px) {
  .ant-collapse-header-text {
    font-size: 20px;
  }
}
@media screen and (max-width: 768px) {
  .ant-collapse-header-text {
    font-size: 18px;
  }
}

.ant-collapse-content.ant-collapse-content-active {
  background-color: transparent !important;
  border: none;
  color: white;
  font-family: "Hellix";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  .ant-collapse-content-box {
    border-radius: unset;
  }
}
.ant-collapse-content.ant-collapse-content-inactive {
  background-color: transparent !important;
  border: none;
  color: transparent;
  font-family: "Hellix";
  .ant-collapse-content-box {
    border-radius: unset;
  }
}
@media screen and (max-width: 1200px) {
  .ant-collapse-content.ant-collapse-content-active {
    font-size: 12px;
  }
}
@media screen and (max-width: 768px) {
  .ant-collapse-content.ant-collapse-content-active {
    font-size: 14px;
  }
}
@media screen and (max-width: 991px) {
  .bitsoImage {
    width: 65px !important;
  }
}

.plusIcon {
  margin-top: 15vh;
  font-size: 25px !important;
  color: #f04a14 !important;
}
@media screen and (max-width: 1200px) {
  .anticon-plus {
    margin-top: 10vh !important;
  }
}
@media screen and (max-width: 992px) {
  .anticon-plus {
    margin-top: 10vh !important;
  }
}
@media screen and (max-width: 768px) {
  .anticon-plus {
    margin-top: 5vh !important;
  }
}
.minusIcon {
  margin-top: 15vh !important;
  font-size: 25px !important;
  color: #f04a14 !important;
}
@media screen and (max-width: 1200px) {
  .minusIcon {
    margin-top: 10vh !important;
  }
}
@media screen and (max-width: 992px) {
  .minusIcon {
    margin-top: 10vh !important;
  }
}
@media screen and (max-width: 768px) {
  .minusIcon {
    margin-top: 5vh !important;
  }
}

.ant-btn .getin_btn {
  height: 20px;
}
.ant-collapse-item.ant-collapse-content.ant-collapse-content-inactive.ant-collapse-content-hidden.ant-collapse-content-box {
  background-color: transparent !important;
  color: transparent !important;
}
